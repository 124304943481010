import { styled } from 'styled-components'

export const App = () => {
  // -- Render --
  return (
    <StRoot>
      <StHeader>
        <StImageContainer target="_blank" href="https://www.raditel.be/nl">
          <StTitle>RADITEL BV</StTitle>
          <StImage src="images/raditel_selexion_logo.png" />
        </StImageContainer>
      </StHeader>
      <StContainer>
        <StImageBlock src="images/raditel_banner.png" />
        <StImageBlock src="images/raditel_content.png" />
        <StImageBlock src="images/raditel_demos.png" />
        <StYtShort
          src="https://www.youtube.com/embed/wEfJj0f-I-s"
          title="Raditel opendeur Pinksteren 23"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></StYtShort>
        <StImageBlock src="images/raditel_footer.png" />
      </StContainer>
    </StRoot>
  )
}

const StRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const StHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem 2rem;
  background-color: #464749;
`

const StImageContainer = styled.a`
  display: flex;
  flex-direction: column;

  max-width: 200px;

  text-decoration: none;
`

const StTitle = styled.h1`
  font:
    600 1.8em Hind,
    sans-serif;
  font-size: 1em;
  color: #fff;
  letter-spacing: 1px;
  text-decoration: none;
  width: 100%;
  margin: 0;
`

const StImage = styled.img`
  max-width: 100%;
`

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem 1rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 768px;
    padding: 4rem 2rem;
  }
`

const StImageBlock = styled.img`
  max-width: 100%;
  height: auto;
`

const StYtShort = styled.iframe`
  aspect-ratio: 9 / 16;
  width: 100% !important;

  max-height: 500px;

  border: none;
`
